import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/pro-light-svg-icons";

export default function TestataEvento({ evento, tipologia }) {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="text-center">{evento.titolo_evento}</h1>
        </div>
      </div>
      <div className="row mt-4 mb-3 text-center">
        <div className="col-12 col-md-4">
          <h2>Info evento</h2>
          <p className="info-evento">
            Id Evento: {evento.id_evento} Edizione: {evento.numero_edizione}
            <br />
            Provider: {evento.ragione_sociale} - Id: {evento.provider_id}
          </p>
        </div>
        <div className="col-12 col-md-4">
          <h2>Contatti segreteria organizzativa:</h2>
          <p>
            <FontAwesomeIcon icon={faPhone} />{" "}
            <a href={`tel:${evento.responsabile_organizzativo_telefono}`}>
              {evento.responsabile_organizzativo_telefono}
            </a>
            <br />
            <FontAwesomeIcon icon={faEnvelope} />{" "}
            <a href={`mailto:${evento.responsabile_organizzativo_email}`}>
              {evento.responsabile_organizzativo_email}
            </a>
          </p>
        </div>
        <div className="col-12 col-md-4">
          <h2>Tipologia evento formativo:</h2>
          <p>{tipologia}</p>
        </div>
      </div>
    </>
  );
}
