import * as React from "react";

export default function EventoDocenti({ docenti }) {
  return (
    <div className="col-12">
      <h3>Docenti</h3>
      <div className="div-overflow-y">
        <table className="table table-striped table-sm">
          <tbody>
            {docenti.map(({ cognome, nome, codice_fiscale, ruolo }) => (
              <tr key={`${cognome}${nome}${codice_fiscale}`}>
                <td>
                  {cognome} {nome}
                </td>
                <td>{codice_fiscale}</td>
                <td>{ruolo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
