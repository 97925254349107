import * as React from "react";

export default function EventoSponsors({ sponsors }) {
  return (
    <div className="col-12 col-md-6">
      <h3>Sponsor</h3>
      <ul className="list-unstyled">
        {sponsors.map((sponsor) => (
          <li key={sponsor}>{sponsor}</li>
        ))}
      </ul>
    </div>
  );
}
